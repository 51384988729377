<template>
  <header
    class="examination-header"
    :class="{ light: isLight, dark: !isLight }"
  >
    <router-link
      to="/"
      class="logo-radbox-link"
      :class="{ light: isLight, dark: !isLight }"
    >
      <span v-html="radBox"></span>
      <span class="portal-name" v-if="showPortalName">
        {{ $t('base.patientPortal') }}
      </span>
    </router-link>
    <span v-if="isLight" v-html="logo" class="logo-pixel"></span>
    <span v-else-if="!isLight" v-html="logoLight" class="logo-pixel"></span>
  </header>
</template>

<script lang="ts">
import logo from '@/assets/logo.svg';
import logoLight from '@/assets/logo-light.svg';
import { defineComponent } from 'vue';
import radBox from '@/assets/alleradVbox.svg';

export default defineComponent({
  name: 'ExaminationHeader',
  props: {
    isLight: {
      type: Boolean,
      required: false,
      default: false
    },
    showPortalName: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup() {
    return { logo, radBox, logoLight };
  }
});
</script>

<style scoped lang="scss">
.logo-radbox-link {
  margin-top: 4px;
  display: flex;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.15s ease, transform 0.15s ease;
  &.light {
    color: var(--font-color-link);
  }
  &.dark {
    color: var(--light);
  }
  .portal-name {
    align-self: flex-end;
    margin-left: 6px;
    font-weight: bold;
    margin-bottom: 3px;
  }
  &:hover {
    color: var(--accent);
  }
  &:active {
    transform: scale(0.95);
  }
  &:focus {
    outline: none;
    border: 1px solid transparent;
  }
  &:focus-visible {
    border: 1px solid var(--accent);
  }
}
.examination-header {
  padding: 6px 15px 8px 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: var(--main-radius);
  align-items: center;
  .logo-pixel {
    margin-top: 4px;
  }
  &.dark {
    background-color: var(--primary);
  }
  &.light {
    background-color: var(--light);
  }
}
</style>
