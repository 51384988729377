
import logo from '@/assets/logo.svg';
import logoLight from '@/assets/logo-light.svg';
import { defineComponent } from 'vue';
import radBox from '@/assets/alleradVbox.svg';

export default defineComponent({
  name: 'ExaminationHeader',
  props: {
    isLight: {
      type: Boolean,
      required: false,
      default: false
    },
    showPortalName: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup() {
    return { logo, radBox, logoLight };
  }
});
