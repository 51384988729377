<template>
  <BaseButton type="danger" class="redirect-button" @click="handleRedirect">
    {{ $t('base.goToMainPage') }}
  </BaseButton>
</template>

<script>
import BaseButton from './BaseButton.vue';
import router from '@/router';
export default {
  name: 'BaseRedirectButton.vue',
  components: { BaseButton },
  props: {
    route: {
      type: String,
      required: false,
      default: '/'
    }
  },
  setup(props) {
    const handleRedirect = () => {
      console.log('s');
      router.push(props.route);
    };
    return {
      handleRedirect
    };
  }
};
</script>

<style scoped lang="scss">
.redirect-link {
  text-decoration: none;
  display: block;
  color: #fff;
  font-size: 0.875rem;
}
.redirect-button {
  padding-right: 20px;
}
</style>
